.slick-prev:before{content:'';
     display:none}

.slick-prev svg{margin-bottom: -4px;}

.slick-next:before{content:''; display:none}

.slick-dots li button:before { content: "\25FC";font-size: 18px;}

/* AC to AB/ML this seems the best place for this to go - feel free to move it */

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-align-items-xs-center {
     align-items: flex-start;
 }

 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-8.MuiGrid-grid-lg-8 {
     padding-top: 0 !important;
     margin-top: 0 !important;
 }

 .Carousel-indicators-41 {
     display: none;
 }

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-12.MuiGrid-grid-lg-12 {
     padding-bottom: 15px;
 }

 .MuiGrid-root.MuiGrid-item.MuiGrid-grid-sm-12.MuiGrid-grid-md-5.MuiGrid-grid-lg-6 h1.MuiTypography-root.makeStyles-currentBitAmount-159.MuiTypography-h6.MuiTypography-colorPrimary {
     margin-top: 0 !important;
 }
 
 button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeLarge.MuiButton-sizeLarge {
     margin-top: 0px !important;
 }

 @media(min-width: 960px){
     .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded .MuiGrid-root.MuiGrid-container {
          min-height: 0px !important;
      }
 }

 @media(max-width: 960px){
     .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
          padding-top: 15px;
          width: 100%;
          
      }
      p + .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2 {
          padding-top: 0px;
          width: 100%;
          
      }
      button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-textSizeLarge.MuiButton-sizeLarge {
          margin-top: 15px !important;
      }
 }

 .makeStyles-contentContainer-155 {
    padding-top: 0 !important;
    margin-top: 0 !important;
 }


 .MuiGrid-spacing-xs-1 > .MuiGrid-item {
    padding-top: 0 !important;
 }

 .makeStyles-container-53 {
    padding-top: 0;
   /* margin-top: -53px !important; */
 }

 p + .MuiGrid-root .MuiGrid-item {
    padding-bottom: 0;
    padding-top: 0;
}


.makeStyles-contentContainer-221 {
    margin-top: -10px !important;
    padding-top: 0 !important;
}

.makeStyles-quickSearch-183 {
    border: 1px solid #ccc !important;
}

@media(max-width: 767px){

.CarouselItem img.MuiCardMedia-root {
    max-height: 300px;
    min-height: 300px;
    margin-bottom: 20px;
}

}

@media(max-width: 992px){
    .slick-dots {
    position: relative !important;
    }
}